import React, { useState } from 'react';

function Tasks() {

    const [tasks, setTasks] = useState([
        { id: 1, name: 'Task 1', deadline: '02/23/2024', priority: 'High', completed: false, comments: '' },
        { id: 2, name: 'Task 2', deadline: '03/15/2024', priority: 'Medium', completed: false, comments: '' },
    ]);

    const handleTaskCompletion = (taskId) => {
        const updatedTasks = tasks.map(task => {
            if (task.id === taskId) {
                return { ...task, completed: !task.completed };
            }
            return task;
        });
        setTasks(updatedTasks);
    };

    const [taskComments, setTaskComments] = useState({});

    const handleAddComment = (taskId, comment) => {
        const timestamp = new Date().toLocaleString();
        setTaskComments(prevComments => ({
            ...prevComments,
            [taskId]: [...(prevComments[taskId] || []), { comment, timestamp }]
        }));
    };

    return (
        <div>
            <h3>Tasks</h3>
            {tasks.map(task => (
                <div key={task.id}>
                    <p>{task.name} - Deadline: {task.deadline} - Priority: {task.priority}</p>
                    <input
                        type="text"
                        placeholder="Add comment"
                        value={taskComments[task.id] ? taskComments[task.id][taskComments[task.id].length - 1].comment : ''}
                        onChange={(e) => setTaskComments(prevComments => ({
                            ...prevComments,
                            [task.id]: [{ comment: e.target.value }]
                        }))}
                    />
                    <button onClick={() => handleAddComment(task.id, taskComments[task.id] ? taskComments[task.id][taskComments[task.id].length - 1].comment : '')}>Add</button>
                    <ul>
                        {taskComments[task.id] && taskComments[task.id].map((comment, index) => (
                            <li key={index}>{comment.timestamp}: {comment.comment}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default Tasks;

