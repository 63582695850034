import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
// import Footer from './components/Footer';
import StudentDashboard from './pages/StudentDashboard';
import ProfessorDashboard from './pages/ProfessorDashboard';

function App() {
    return (
        <Router>
            <div className="App">
                <p>Hey, welcome to Volunteer App</p>
                <Header />
                <Routes>
                <Route path="/student" element={<StudentDashboard/>} />
                <Route path="/professor" element={<ProfessorDashboard/>} />
                </Routes>
                {/* <Footer /> */}
            </div>
        </Router>
    );
}

export default App;
