import React, { useState } from 'react';

function TaskAssignment() {
    const [taskName, setTaskName] = useState('');
    const [description, setDescription] = useState('');
    const [deadline, setDeadline] = useState('');
    const [priority, setPriority] = useState('');
    const [assignedStudent, setAssignedStudent] = useState('');

    const students = [
        { id: 1, name: 'Student 1' },
        { id: 2, name: 'Student 2' },
        { id: 3, name: 'Student 3' },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Task Created:', { taskName, description, deadline, priority, assignedStudent });
        setTaskName('');
        setDescription('');
        setDeadline('');
        setPriority('');
        setAssignedStudent('');
    };

    return (
        <div>
            <h3>Task Assignment</h3>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="taskName">Task Name:</label>
                    <input type="text" id="taskName" value={taskName} onChange={(e) => setTaskName(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="deadline">Deadline:</label>
                    <input type="date" id="deadline" value={deadline} onChange={(e) => setDeadline(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="priority">Priority:</label>
                    <select id="priority" value={priority} onChange={(e) => setPriority(e.target.value)} required>
                        <option value="">Select Priority</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="assignedStudent">Assign To:</label>
                    <select id="assignedStudent" value={assignedStudent} onChange={(e) => setAssignedStudent(e.target.value)} required>
                        <option value="">Select Student</option>
                        {students.map(student => (
                            <option key={student.id} value={student.id}>{student.name}</option>
                        ))}
                    </select>
                </div>
                <button type="submit">Create Task</button>
            </form>
        </div>
    );
}

export default TaskAssignment;
