import React from 'react';
import Calendar from 'react-calendar';
import '../styles/calendar.css';

function CustomCalendar({ tasks }) {
    const getTileClassName = ({ date }) => {
        const formattedDate = date.toLocaleDateString('en-US');
        console.log(formattedDate);
        console.log(formattedDate===tasks[0].deadline);
        const hasDeadline = tasks.some(task => task.deadline === formattedDate);
        return hasDeadline ? 'highlighted' : null;
    };

    const getTileContent = ({ date }) => {
        const formattedDate = date.toLocaleDateString('en-US');
        const taskOnDate = tasks.find(task => task.deadline === formattedDate);
        return taskOnDate ? <small>  Task - {taskOnDate.id}</small> : null;
    };

    return (
        <div>
            <h3>Calendar</h3>
            <Calendar tileClassName={getTileClassName} tileContent={getTileContent} />
        </div>
    );
}

export default CustomCalendar;

