import React, { useState } from 'react';

function WeeklyReportForm() {
    const [studentDetails, setStudentDetails] = useState('');
    const [professorDetails, setProfessorDetails] = useState('');
    const [assignmentDetails, setAssignmentDetails] = useState('');
    const [hoursAnalysis, setHoursAnalysis] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <h3>Weekly Report Form</h3>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Student Details" value={studentDetails} onChange={(e) => setStudentDetails(e.target.value)} />
                <input type="text" placeholder="Professor Details" value={professorDetails} onChange={(e) => setProfessorDetails(e.target.value)} />
                <input type="text" placeholder="Assignment Details" value={assignmentDetails} onChange={(e) => setAssignmentDetails(e.target.value)} />
                <input type="text" placeholder="Hours Analysis" value={hoursAnalysis} onChange={(e) => setHoursAnalysis(e.target.value)} />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default WeeklyReportForm;
