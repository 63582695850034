import React from 'react';
import Tasks from '../components/Tasks';
import CustomCalendar from '../components/Calendar';
import WeeklyReportForm from '../components/WeeklyReportForm';

function StudentDashboard() {
    // Sample tasks data
    const tasks = [
        { id: 1, name: 'Task 1', deadline: '3/3/2024', priority: 'High', completed: false, comments: '' },
        { id: 2, name: 'Task 2', deadline: '3/15/2024', priority: 'Medium', completed: false, comments: '' },
    ];
    

    return (
        <div>
            <h2>Student Dashboard</h2>
            <div>
                <h3>Tasks</h3>
                <Tasks tasks={tasks} />
            </div>
            <div>
                <h3>Calendar</h3>
                <CustomCalendar tasks={tasks} />
            </div>
            <div>
                <h3>Weekly Reports</h3>
                <WeeklyReportForm />
            </div>
        </div>
    );
}

export default StudentDashboard;
