import React, { useState } from 'react';

function RecommendationLetter() {

    const [studentName, setStudentName] = useState('');
    const [recommendationContent, setRecommendationContent] = useState('');

    const handleGenerateLetter = (e) => {
        e.preventDefault();
        const recommendationLetter = `To Whom It May Concern,

        I am pleased to write this letter of recommendation for ${studentName}. ${recommendationContent}

        Sincerely,
        [Professor's Name]`;

        const blob = new Blob([recommendationLetter], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${studentName}_Recommendation_Letter.txt`;
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        setStudentName('');
        setRecommendationContent('');
    };

    return (
        <div>
            <h3>Recommendation Letter</h3>
            <form onSubmit={handleGenerateLetter}>
                <div>
                    <label htmlFor="studentName">Student Name:</label>
                    <input type="text" id="studentName" value={studentName} onChange={(e) => setStudentName(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="recommendationContent">Recommendation Content:</label>
                    <textarea id="recommendationContent" value={recommendationContent} onChange={(e) => setRecommendationContent(e.target.value)} required />
                </div>
                <button type="submit">Generate Letter</button>
            </form>
        </div>
    );
}

export default RecommendationLetter;
