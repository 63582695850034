import React from 'react';
import TaskAssignment from '../components/TaskAssignment';
import ReportReview from '../components/ReportReview';
import RecommendationLetter from '../components/RecommendationLetter';

function ProfessorDashboard() {
    return (
        <div>
            <h2>Professor Dashboard</h2>
            <TaskAssignment />
            <ReportReview />
            <RecommendationLetter />
        </div>
    );
}

export default ProfessorDashboard;
