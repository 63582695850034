import React, { useState } from 'react';

function ReportReview() {

    const [reports, setReports] = useState([
        { id: 1, student: 'Student1', date: '2024-02-10', content: 'weekly report for Task 1.', feedback: '' },
        { id: 2, student: 'Student2', date: '2024-02-11', content: 'weekly report for Task 2.', feedback: '' },
        { id: 3, student: 'Student3', date: '2024-02-12', content: 'weekly report for Task 3.', feedback: '' },
    ]);

    const [feedbackText, setFeedbackText] = useState('');

    const handleFeedbackSubmit = (reportId) => {
        setReports(prevReports => prevReports.map(report =>
            report.id === reportId ? { ...report, feedback: feedbackText } : report
        ));
        setFeedbackText('');
    };

    return (
        <div>
            <h3>Report Review</h3>
            <ul>
                {reports.map(report => (
                    <li key={report.id}>
                        <h4>{report.student} - {report.date}</h4>
                        <p>{report.content}</p>
                        <textarea
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                            placeholder="Enter feedback...."
                        />
                        <button onClick={() => handleFeedbackSubmit(report.id)}>Submit Feedback</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ReportReview;
